import React from 'react'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'
import SEO from '../components/SEO'
import { SessionAuthenticator, withSessionContext, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { withPingContext, PingContext } from '../context/ping'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import qs from 'qs'

interface EditHostProps extends WithTranslation {
  location: any
  notificationContext: NotificationContext
  sessionContext: SessionContext
  pingContext: PingContext
}

class AuthenticatedEditHostPage extends React.Component<EditHostProps> {
  state = {
    newHostId: null,
    newHostMethod: '',
    newHostUrl: '',
    newHostFrequency: '',
    newHostGroup: '',
    newHostExpectedStatus: '',
    newHostExpectedContent: '',
    newHostWebhookUrl: '',
    newHostInstantRetry: '0',
  }

  async componentWillMount() {
    this.setState({
      newHostId: null,
    })
    const query = qs.parse(this.props.location.search.slice(1))
    try {
      const hostResponse = await this.props.pingContext.fetchHost(query.hostId)
      this.setState({
        newHostId: hostResponse.hostId,
        newHostMethod: hostResponse.method,
        newHostUrl: hostResponse.url,
        newHostFrequency: hostResponse.frequency,
        newHostGroup: hostResponse.group || '',
        newHostExpectedStatus: hostResponse.expectedStatus || '',
        newHostExpectedContent: hostResponse.expectedContent || '',
        newHostWebhookUrl: hostResponse.webhookUrl || '',
        newHostInstantRetry: '' + (hostResponse.instantRetry || '0'),
      })
    } catch (err) {
      this.props.notificationContext.addErrorNotification(err)
    }
  }

  componentWillUnmount() {
  }

  handleUpdateHost = async (event: any) => {
    event.preventDefault()
    console.log(this.state)
    if (!this.state.newHostId || !this.state.newHostMethod || !this.state.newHostUrl) return
    await this.props.pingContext.updateHost(this.state.newHostId, {
      method: this.state.newHostMethod,
      url: this.state.newHostUrl,
      frequency: this.state.newHostFrequency,
      group: this.state.newHostGroup,
      expectedStatus: this.state.newHostExpectedStatus,
      expectedContent: this.state.newHostExpectedContent,
      webhookUrl: this.state.newHostWebhookUrl,
      instantRetry: parseInt(this.state.newHostInstantRetry, 10),
    })
    navigate('/dashboard/')
  }

  handleDeleteHost = async (event: any) => {
    event.preventDefault()
    if (!confirm('Really delete host ' + this.props.pingContext.host.url + '?')) return
    await this.props.pingContext.deleteHost(this.props.pingContext.host.hostId)
    navigate('/dashboard/')
  }

  render() {
    return (
      <div className="container">
        <div className="page-nav mb-1">
          <h1 className="mt-5"><Trans i18nKey="edit_title">Edit Host</Trans></h1>
          <div className="buttons">
          </div>
        </div>
        {this.props.pingContext.host && this.state.newHostId ?
        <form className="bg-white mt-1 p-3" onSubmit={this.handleUpdateHost}>
          <div className="row">
            <div className="col-lg-10">
              <div className="form-group">
                <label><Trans i18nKey="edit_url_to_monitor">URL to monitor</Trans></label>
                <input type="text" className="form-control" placeholder={this.props.t("edit_url_placeholder", "Enter full URL such as https://example.com")} value={this.state.newHostUrl} onChange={(event) => this.setState({newHostUrl:event.target.value})}/>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-group">
                <label><Trans i18nKey="edit_http_method">HTTP Method</Trans></label>
                <select className="form-control" value={this.state.newHostMethod} onChange={(event) => this.setState({newHostMethod:event.target.value})}>
                  <option>GET</option>
                  <option>HEAD</option>
                  <option>POST</option>
                  <option>PUT</option>
                  <option>DELETE</option>
                  <option>OPTIONS</option>
                  <option>PATCH</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><Trans i18nKey="edit_ping_frequency">Ping Frequency</Trans></label>
                <select className="form-control" value={this.state.newHostFrequency} onChange={(event) => this.setState({newHostFrequency:event.target.value})}>
                  <option value="DISABLED">DISABLED</option>
                  <option value="5MIN">5MIN</option>
                  <option value="HOUR">HOUR</option>
                  <option value="DAY">DAY</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><Trans i18nKey="edit_instant_retry">Instant Retry (before reporting error)</Trans></label>
                <select className="form-control" value={this.state.newHostInstantRetry || '0'} onChange={(event) => this.setState({newHostInstantRetry:event.target.value})}>
                  <option value="0">DISABLED</option>
                  <option value="1">1 RETRY</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><Trans i18nKey="edit_expected_status">Expected status</Trans></label>
                <input type="text" className="form-control" maxLength={8} placeholder={this.props.t("edit_expected_status_placeholder", "E.g. 200 or 301")} value={this.state.newHostExpectedStatus} onChange={(event) => this.setState({newHostExpectedStatus:event.target.value})}/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label><Trans i18nKey="edit_expected_content">Expected content</Trans></label>
                <input type="text" className="form-control" maxLength={64} placeholder={this.props.t("edit_expected_content_placeholder", "E.g. front page title")} value={this.state.newHostExpectedContent} onChange={(event) => this.setState({newHostExpectedContent:event.target.value})}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label><Trans i18nKey="edit_webhook_url">Webhook URL (encrypted &amp; stored securely)</Trans></label>
                <input type="text" className="form-control" placeholder={this.props.t("edit_webhook_url_placeholder", "Enter full URL such as https://example.com?status={status}")} value={this.state.newHostWebhookUrl} onChange={(event) => this.setState({newHostWebhookUrl:event.target.value})}/>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary"><Trans i18nKey="edit_update">Update Host</Trans></button>
            {' '}
            <Link to="/dashboard/" className="btn btn-secondary"><Trans i18nKey="edit_cancel">Cancel</Trans></Link>
            <button type="button" className="float-right pull-right btn btn-danger" onClick={this.handleDeleteHost}><span className="fa fa-times"></span> <Trans i18nKey="edit_delete">Delete</Trans></button>
          </div>
        </form>
        :
        <div><span className="fa fa-refresh fa-spin"></span> <Trans i18nKey="edit_loading">Loading...</Trans></div>
        }
      </div>
    )
  }
}

class EditHostPage extends React.Component<EditHostProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Edit host"} description="Clouden Ping" />
        <SessionAuthenticator>
          <AuthenticatedEditHostPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(withSessionContext(withPingContext(EditHostPage))))
